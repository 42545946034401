function isCached(imgUrl) {
  if (typeof Image !== "undefined") {
    var img = new Image();
    img.src = imgUrl;
    return img.complete || img.width + img.height > 0;
  } else {
    return false;
  }
}

export function isOnMobile() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  )
    return true;
  else return false;
}

export function preloadImages(imageUrls) {
  let images = [];
  for (var i = 0; i < imageUrls.length; i++) {
    if (!isCached(imageUrls[i])) {
      if (typeof Image !== "undefined") {
        images[i] = new Image();
        images[i].src = imageUrls[i];
      }
    }
  }
}
