import React from "react";
import { useSpring, animated } from "react-spring";

function SayHiEmail() {
  const animationStyleProp = useSpring({ opacity: 1, from: { opacity: 0 } });
  return (
    <animated.div
      style={animationStyleProp}
      className="flex flex-col md:flex-row md:items-end"
    >
      <h1 className="font-serif text-3xl md:text-6xl text-center text-blue mr-1 mb-4 md:mb-0">
        Say hello.
      </h1>
      <h1 className="font-serif text-lg md:text-4xl text-center text-grey-darker md:mb-1 md:ml-1">
        saraalnasser93 [at] gmail.com
      </h1>
    </animated.div>
  );
}

function SayHiNoEmail() {
  const animationStyleProp = useSpring({ opacity: 1, from: { opacity: 0 } });
  return (
    <animated.div
      style={animationStyleProp}
      className="flex justify-center items-end"
    >
      <h1 className="font-serif md:text-6xl text-center text-blue">
        Say hello.
      </h1>
    </animated.div>
  );
}

function SayHi({ showEmail }) {
  return showEmail ? <SayHiEmail /> : <SayHiNoEmail />;
}

SayHi.defaultProps = {
  showEmail: true,
};

export default SayHi;
