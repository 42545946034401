import React, { useState } from "react";
import { graphql } from "gatsby";
import { useSpring, animated } from "react-spring";
import SEO from "../components/seo";
import Layout from "../components/layout";
import BottomNav from "../components/bottom-nav";
import SayHi from "../components/sayhi";
import { preloadImages, isOnMobile } from "../utils";

import "./index.css";

function MainHeadline() {
  const animationStyleProp = useSpring({ opacity: 1, from: { opacity: 0 } });
  const borderBottomStyle = { borderBottom: "7px solid" };
  return (
    <animated.div style={animationStyleProp}>
      <p className="flex-1 mx-2 md:mx-8 font-serif text-4xl md:text-7xl text-center text-orange leading-tight">
        Using <span style={borderBottomStyle}>Data Science</span>
        <br />
        to solve
        <br />
        <span style={borderBottomStyle}>UX</span> problems
      </p>
      <div className="flex justify-center items-end mt-8 mb:mt-16">
        <svg
          className="fill-current text-red inline-block h-5 mb mx-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
        </svg>
        <p className="text-center text-sm md:text-lg text-red tracking-wide">
          Pittsburgh, PA
        </p>
      </div>
    </animated.div>
  );
}

function ProjectImage({ index, data }) {
  const animationStyleProp = useSpring({ opacity: 1, from: { opacity: 0 } });
  let src = "";
  let alt = "Project";

  if (index === 1) {
    src = data.spaceJam.publicURL;
  } else if (index === 2) {
    src = data.evie.publicURL;
  } else if (index === 3) {
    src = data.uoftAcc.publicURL;
  } else if (index === 4) {
    src = data.photography.publicURL;
    alt = "Photography";
  }

  return (
    <animated.img
      src={src}
      alt={alt}
      style={{ height: window.innerHeight - 250, ...animationStyleProp }}
    />
  );
}

export default ({ data }) => {
  const [projectIndex, setProjectIndex] = useState(0);

  preloadImages([
    data.evie.publicURL,
    data.spaceJam.publicURL,
    data.uoftAcc.publicURL,
    data.photography.publicURL,
  ]);

  function updateProjectIndex(newProjectIndex) {
    if (!isOnMobile()) setProjectIndex(newProjectIndex);
  }

  return (
    <Layout indexPage={true}>
      <SEO title="Home" keywords={["sara", "alnasser"]} />
      <div className="flex flex-col items-center">
        {projectIndex === 0 ? (
          <div className="flex-1">
            <MainHeadline />
          </div>
        ) : projectIndex === 5 ? (
          <SayHi showEmail={false} />
        ) : (
          <ProjectImage index={projectIndex} data={data} />
        )}
      </div>
      <BottomNav setProjectIndex={updateProjectIndex} indexPage={true} />
    </Layout>
  );
};

export const query = graphql`
  query {
    uoftAcc: file(relativePath: { eq: "uoft_acc.png" }) {
      publicURL
    }

    evie: file(relativePath: { eq: "evie.png" }) {
      publicURL
    }

    spaceJam: file(relativePath: { eq: "space_jam.jpg" }) {
      publicURL
    }

    photography: file(relativePath: { eq: "photo_palace.jpg" }) {
      publicURL
    }
  }
`;
